<template>
  <div class="container bg-white mt-1">
    <div class="p-48">
      <div>
        <div class="mb-3 d-flex justify-content-between py-1">
          <p class="fs-18 fw-700 txt-black mb-1">{{ $t('lbl_doctor_information') }}</p>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="close"
            class="cursor-pointer"
          >
            <path
              d="M6 18.4998L17.9998 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.9998 18.4998L6 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <!-- // -->
        <div class="mt-4 border border-indigo-600 rounded-3xl d-flex items-center">
          <div class="p-6">
            <!-- <span
              v-if="form.user && form.user.avatar"
              class="avatar avatar-sm avatar-rounded"
              style="
                                      width: 100px;
                                      height: 100px;
                                      border-radius: 50px;
                                      background-size: 100px;
                                      background-position: center;"
              :style="'background-image: url(' + getImageURL(form.user && form.user.avatar) + ')'"
            ></span>-->
            <ImageBox
              v-if="form.user && form.user.avatar"
              class="avatar avatar-sm avatar-rounded object-cover object-top w-28 h-28 border"
              avatar
              :source="form.user && getImageURL(form.user.avatar)"
              :alt="form.name"
            />
            <span
              v-if="form.user && !form.user.avatar"
              class="avatar avatar-sm avatar-rounded bg-pri text-white fs-18"
              style="
                                        width: 100px;
                                        height: 100px;
                                        border-radius: 50px;
                                        background-size: 100px;
                                        background-position: center;"
            >{{ form.name.charAt(0) }}</span>
          </div>
          <div class="mt-0">
            <p class="fs-20 fw-700 txt-pri">{{ form.name }}</p>
            <p class="fs-16 fw-600 mb-2 txt-grey-700">
              <svg
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 16H18V8H6V16ZM8 10H16V14H8V10ZM4 15H2V18C2 19.1 2.9 20 4 20H7V18H4V15ZM4 6H7V4H4C2.9 4 2 4.9 2 6V9H4V6ZM20 4H17V6H20V9H22V6C22 4.9 21.1 4 20 4ZM20 18H17V20H20C21.1 20 22 19.1 22 18V15H20V18Z"
                  fill="#475467"
                />
              </svg>
              {{ form.code }}
            </p>
            <p class="mb-0">
              <button
                type="button"
                class="btn bg-pri text-white mr-2 border-0 px-4 py-2"
                v-if="$user.id != form.user_id"
                @click="messageToDirect(form.user_id, $event)"
              >
                <svg
                  class="mr-2"
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 17C16.771 17 18.657 17 19.828 15.828C21 14.657 21 12.771 21 9C21 5.229 21 3.343 19.828 2.172C18.657 1 16.771 1 13 1H9C5.229 1 3.343 1 2.172 2.172C1 3.343 1 5.229 1 9C1 12.771 1 14.657 2.172 15.828C2.825 16.482 3.7 16.771 5 16.898"
                    stroke="#FCFCFD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13 17C11.764 17 10.402 17.5 9.159 18.145C7.161 19.182 6.162 19.701 5.67 19.37C5.178 19.04 5.271 18.015 5.458 15.966L5.5 15.5"
                    stroke="#FCFCFD"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>

                <span>Chat</span>
                <div v-if="loading" class="spinner-border mx-2 loading-btn-icon" role="status">
                  <div class="sr-only">Loading...</div>
                </div>
              </button>
            </p>
            <!-- <p class="fs-16 fw-600 txt-grey-700">
              <svg
                class="mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 10.99L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
                  fill="#475467"
                />
              </svg>
              {{ form.user && form.user.email }}
            </p>-->
          </div>
          <!-- <div class="ml-5 mt-5">
            <button
              type="button"
              class="btn bg-pri text-white mr-2 border-0 px-4 py-2"
              v-if="$user.id != form.user_id"
              @click="messageTo(form.user_id, $event)"
            >
              <svg
                class="mr-2"
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 17C16.771 17 18.657 17 19.828 15.828C21 14.657 21 12.771 21 9C21 5.229 21 3.343 19.828 2.172C18.657 1 16.771 1 13 1H9C5.229 1 3.343 1 2.172 2.172C1 3.343 1 5.229 1 9C1 12.771 1 14.657 2.172 15.828C2.825 16.482 3.7 16.771 5 16.898"
                  stroke="#FCFCFD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13 17C11.764 17 10.402 17.5 9.159 18.145C7.161 19.182 6.162 19.701 5.67 19.37C5.178 19.04 5.271 18.015 5.458 15.966L5.5 15.5"
                  stroke="#FCFCFD"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>

              <span>Chat</span>
              <div v-if="loading" class="spinner-border mx-2 loading-btn-icon" role="status">
                <div class="sr-only">Loading...</div>
              </div>
            </button>
          </div>-->
        </div>
        <!-- // -->
        <div class="mt-10 border border-indigo-600 ... rounded-3xl">
          <div class="p-3">
            <p class="fs-20 fw-700 txt-pri">{{ $t('lbl_job_information') }}</p>
            <div class="mb-8">
              <p class="fs-16 fw-500 txt-grey-600 mb-2">{{ $t('lbl_work_place') }}</p>
              <p class="fs-16 fw-600 txt-grey-800">{{ form.work }}</p>
            </div>
            <div class="mb-8">
              <p class="fs-16 fw-500 txt-grey-600 mb-2">{{ $t('lbl_job_title') }}</p>
              <p class="fs-16 fw-600 txt-grey-800">{{ form.chuc_vu }}</p>
            </div>
            <div>
              <p class="fs-16 fw-500 txt-grey-600 mb-2">{{ $t('lbl_specialist') }}</p>
              <div
                class="justify-content-start"
                v-for="(item, index) in form.specialists"
                :key="index"
              >
                <p class="fs-16 fw-600 txt-grey-800">{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- // -->
        <div class="mt-10">
          <div class="d-flex">
            <div class="p-3 w-50 border border-indigo-600 ... rounded-3xl mr-3">
              <p class="fs-20 fw-700 txt-pri">{{ $t('lbl_education') }}</p>
              <div v-for="(item, index) in form.exps" :key="index">
                <p
                  class="fs-16 fw-600 txt-grey-900 mb-2"
                >{{ item.noi_cong_tac + ' - ' + item.chuc_vu }}</p>
                <p class="txt-grey-700 fs-14 fw-400">
                  {{ (item.start_year || getCurrentYear()) + ' - ' +
                  getEndTime(item.end_year, item.start_year) }}
                </p>
              </div>
            </div>

            <div class="p-3 w-50 border border-indigo-600 ... rounded-3xl">
              <p class="fs-20 fw-700 txt-pri">{{ $t('lbl_work_experience') }}</p>
              <div v-for="(item, index) in form.edus" :key="index">
                <p
                  class="fs-16 fw-600 txt-grey-900 mb-2"
                >{{ item.noi_tu_nghiep + ' - ' + item.chuyen_nganh }}</p>
                <p class="txt-grey-700 fs-14 fw-400">
                  {{ (item.start_year || getCurrentYear()) + ' - ' +
                  getEndTime(item.end_year, item.start_year) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageBox from '@/components/Common/Image.vue'
import moment from 'moment'
import appUtils from '../utils/appUtils'
import { mixinGoToChat } from '../utils/mixinGoToChat'

export default {
  name: 'DoctorProfile',
  components: { ImageBox },
  mixins: [mixinGoToChat],
  data () {
    return {
      loading: false,
      qr_code: '',
      form: '',
      list_exps: null,
      list_process: null,
      tags: [],
      user: null,
      appUtils
    }
  },
  mounted () {
    this.getDoctorInfo()
  },
  methods: {
    async messageToDirect (id, e) {
      await this.setSelectedMember(this.form)
      await this.messageTo(id, e)
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    async getDoctorInfo () {
      const res = await this.$rf
        .getRequest('AuthRequest')
        .getDoctorInfo(this.$route.params.id)
      this.form = res.data
    },
    close () {
      this.$router.go(-1)
    },
    getCurrentYear () {
      return moment().format('YYYY')
    },
    getEndTime (endTime, startTime) {
      if (!endTime) return this.$t('lbl_now')

      if (
        Number(endTime) > Number(this.getCurrentYear) ||
        Number(startTime) > Number(endTime)
      ) {
        return ''
      } else {
        return endTime
      }
    }
  }
}
</script>

<style scoped>
.p-48 {
  padding: 20px 48px 100px 48px;
}

.container {
  width: 1024px;
}

.loading-btn-icon {
  display: none;
}

.loading-btn .loading-btn-icon {
  display: block !important;
}
</style>
